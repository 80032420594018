.gear-table {

    th,
    td {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .copy-cell {
        cursor: pointer;

        &:active {
            outline: 1px solid #069CE6;
        }
    }
}