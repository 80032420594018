.record-foldable {
    cursor: pointer;

    .foldable-title {
        padding: 12px;
    }

    .action-title {
        margin-right: 10px;
    }

    &.open-queries {
        background-color: #f7cccc;

        .foldable-content {
            background-color: #fceded;
        }
    }

    &.closed-queries {
        background-color: #c5b9b9;
    }
}

.field-query-table {
    margin-top: 0;
}

.field-history-table,
.field-query-table {
    margin-top: 1rem;

    .date {
        width: 150px;
    }

    .user {
        width: 150px;
    }

    .light-red-bg {
        background-color: #fac0c0;
    }

    .red-border {
        border: 3px solid red;
    }
}