.btn-group-toggle {
    .btn-success {
        background-color: #74788d;
        border-color: #74788d;
    }


    .btn-success:not(:disabled):not(.disabled):active,
    .btn-success:not(:disabled):not(.disabled).active {
        background-color: #5d6071;
        border-color: #5d6071;
    }

    .btn-success:not(:disabled):not(.disabled):active:focus,
    .btn-success:not(:disabled):not(.disabled).active:focus {
        box-shadow: none;
    }

    .btn-success:focus {
        box-shadow: none;
    }
}

.tab-group {
    // position: relative;
    display: inline-flex;
    // vertical-align: middle;
    border-bottom: 1px solid #CCDAE2;
    width: 100%;
}

.button-menu {
    max-width: 1024px;

    &>button {
        width: 100%;
    }

    & .flex-box-reverse {
        white-space: break-spaces;
    }

    @media only screen and (max-width: 992px) {
        & {
            width: 100%;
            max-width: none;
        }
    }
}

.button-list-table {
    border-collapse: separate;
    border-spacing: 0;

    tr td {
        border-top: 1px solid #CDDAE2;
    }

    tr:first-child td {
        border-top: none;
    }

    td.noborder {
        border-top: none;
        width: 20px;
        padding: 0;
    }

    @media only screen and (max-width: 992px) {
        td.due-date {
            max-width: 100px;
            white-space: normal;
        }
    }
}