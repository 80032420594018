.search-result-box {
    position: absolute;
    background-color: white;
    z-index: 10;
    right: 0;
    top: 63px;
    border: 1px solid #ddd;
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
    min-width: 500px;

    .search-result-table-wrapper {
        max-height: 500px;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 15px;

        table {
            thead {
                th {
                    color: #007BFF;

                    &:first-child {
                        min-width: 150px;
                    }

                    &:nth-child(2) {
                        min-width: 190px;
                    }

                    &:nth-child(4) {
                        min-width: 150px;
                    }
                }
            }

            tbody {
                tr {
                    margin-top: 10px;

                    td {
                        &:first-child {
                            cursor: pointer;
                            color: #007BFF;
                            text-decoration: underline;
                        }

                        &.bold {
                            font-weight: bold;
                        }
                    }
                }
            }

            th,
            td {
                padding: 0.5rem;
            }
        }
    }

    button.close {
        padding-left: 10px;
    }

}

@media only screen and (max-width: 992px) {
    .search-result-box {
        min-width: inherit;
        width: 350px;

        .search-result-table-wrapper {
            overflow-x: auto;
        }
    }
}

@media only screen and (max-width: 400px) {
    .search-result-box {
        min-width: inherit;
        width: 320px;
    }
}