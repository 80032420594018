.multi-unit-select {
    appearance: none;
    -webkit-appearance: none;
    /* safari and chrome */
    -moz-appearance: none;
    /* Mozilla */
    -ms-appearance: none;
    /* Ie */
    position: relative;
    background: url(../../../../Common/assets/images/arrow-down.png) no-repeat right;
    background-position: calc(100% - 6px) center !important;
    // background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    padding: 10px 30px 10px 10px;
    font-size: 0.7rem;
    height: 45.17px;
}

.multi-unit-input-field {
    width: 300px;

    .input-group-prepend {
        min-width: 150px;
    }
}

.oz-input-field {
    width: 190px;
}

.multi-unit-append {
    width: 50px;
}

.multi-unit-description {
    margin: 0;
    color: #303030;
}