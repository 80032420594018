//
// _menu.scss
// 

.metismenu {
    margin: 0;

    li {
        display: block;
        width: 100%;
    }

    .mm-collapse {
        display: none;

        &:not(.mm-show) {
            display: none;
        }

        &.mm-show {
            padding: .2875rem 0 0;
            display: block
        }

        > .sub-menu-bottom-border {
            width: 232px;
            margin: 0.5rem 1.25rem 0.25rem auto;
            background-color: rgba($header-bg, 0.2);
        }
    }

    .mm-collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition-timing-function: ease;
        transition-duration: .35s;
        transition-property: height, visibility;
    }
}

.vertical-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1001;
    width: $sidebar-width;
    margin-top: 0;
    background: $sidebar-bg;
    box-shadow: $box-shadow;
    transition-duration: .4s;
    overflow-x: hidden;
    overflow-y: auto;
}

.main-content {
    margin-left: $sidebar-width;
    // overflow: hidden;

    .content {
        padding: 0 15px 10px 15px;
        margin-top: $header-height;
    }
}

#sidebar-menu {
    padding: 0 0 30px 0;

    .has-arrow:after {
        transform: rotate(-90deg);
        color: $sidebar-menu-collapse-color;
    }

    .mm-active {
        >.has-arrow {
            &:after {
                transform: rotate(0deg);
                color: rgba($sidebar-bg, 1);
            }
        }
    }

    .has-arrow {
        &:after {
            content: "\F0140";
            margin-left: auto;
            font-family: 'Material Design Icons';
            display: block;
            float: right;
            transition: transform .2s;
            font-size: 1rem;
        }
    }

    > ul {
        &:hover {
            & > li {
                &:not(&:hover) {
                    > a.active, > a.mm-active {
                        // background: none !important;
                    }
                }

                &:hover {
                    > a {
                        background-color: $sidebar-menu-item-hover-text-color;

                        &.active, &.mm-active {
                            background-color: $header-bg;
                        }
                    }
                }
            }
        }

        > li {

            > a {
                padding: .5rem 22px 0.5rem 0.5rem !important;
                margin: 0 0 0 0.75rem;
                border-radius: 2rem 0 0 2rem;

                > span, p > span {
                    white-space: nowrap;
                    margin-left: 0.5rem;
                }

                &.active, &.mm-active {
                    background-color: $header-bg;
                    p span, p svg {
                        color: $sidebar-menu-item-active-color;
                    }
                }
            }

        }
    }

    ul {
        li {
            a {
                height: $sidebar-menu-item-height;
                display: flex;
                align-items: center;
                padding: .625rem 1.5rem;
                color: $sidebar-menu-item-color;
                position: relative;
                font-size: .79rem;
                transition: all .4s;

                i, svg {
                    display: inline-block;
                    min-width: 1.75rem;
                    font-size: 1.25rem;
                    line-height: 1.40625rem;
                    vertical-align: middle;
                    color: white;
                    transition: all .4s;

                }
            }

            .badge {
                margin-top: 4px;
            }

            ul.sub-menu {
                li {

                    a {
                        padding: .6125rem 22px .6125rem 2.1rem;
                        font-size: 15px;
                        color: $sidebar-menu-sub-item-color;
                               
                        p {
                            display: flex;
                            align-items: center;

                            span {
                                white-space: nowrap;
                                margin-left: 0.5rem;
                                max-width: 140px;
                                text-overflow: ellipsis;
                                overflow-x: hidden;
                            }
                        }

                        i, svg {
                            color: $sidebar-menu-sub-item-color;
                        }

                        
                        &:hover {
                            color: white;

                            i, svg {
                                color: white;
                            }

                            .badge {
                                color: #fff;
                                background-color: #feffff47;
                            }
                        }

                        &.active {
                            i, svg {
                                color: $sidebar-menu-item-active-text-color;
                            }
                        } 
                    }
                }
            }
        }

    }
}

.menu-title {
    padding: 12px 20px !important;
    letter-spacing: .05em;
    pointer-events: none;
    cursor: default;
    font-size: 11px;
    text-transform: uppercase;
    color: $sidebar-menu-item-icon-color;
    font-weight: $font-weight-semibold;
}

.mm-active {
    color: $sidebar-menu-item-active-color !important;
    .active {
        color: $sidebar-menu-item-active-color !important;

        p span, p svg {
            color: $sidebar-menu-item-active-text-color;
        }

        .badge {
            color: $sidebar-menu-item-active-text-color !important;
            background-color: $sidebar-menu-item-active-badge-bg-color !important;
        }
        i {
            color: $sidebar-menu-item-active-color !important;
        }
    }
    > i {
        color: $sidebar-menu-item-active-color !important;
    }
}

@media (max-width: 992px) {
    .vertical-menu {
        left: -$sidebar-collapsed-width;
        width: $sidebar-collapsed-width;

        #sidebar-menu > ul > li > a span {
            opacity: 0;
        }
    }

    .main-content {
        margin-left: 0 !important;
    }

    body.sidebar-enable {
        .vertical-menu {
            left: 0;
        }
    }
}

// Enlarge menu
.vertical-collpsed {
    &.menu-opened {
        .vertical-menu {
            &:hover {
                width: $sidebar-width !important;

                #sidebar-menu {
                    > ul > li > a span {
                        opacity: 1;
                    }

                    .navbar-brand-box {
                        width: $sidebar-width !important;
                    }

                    .logo {
                        span.logo-lg {
                            display: block;
                        }

                        span.logo-sm {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .main-content {
        margin-left: $sidebar-collapsed-width;
    }

    .navbar-brand-box {
        width: $sidebar-collapsed-width !important;
    }

    .logo {
        span.logo-lg {
            display: none;
        }

        span.logo-sm {
            display: block;
        }
    }

    // Side menu
    .vertical-menu {
        position: absolute;
        width: $sidebar-collapsed-width !important;

        .simplebar-mask,
        .simplebar-content-wrapper {
            overflow: visible !important;
        }

        .simplebar-scrollbar {
            display: none !important;
        }

        .simplebar-offset {
            bottom: 0 !important;
        }

        // Sidebar Menu
        #sidebar-menu {

            .nav.collapse {
                height: inherit !important;
            }

            .has-arrow {
                &:after {
                    display: none;
                }
            }

            > ul {
                > li {
                    position: relative;
                    white-space: nowrap;

                    > a {
                        padding: 15px 20px;
                        transition: none;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $sidebar-menu-item-hover-color;
                        }

                        span {
                            opacity: 0;
                            transition-duration: .5s;
                        }
                    }
                }
            }
        }

    }
}



body[data-layout="horizontal"] {
    .main-content {
        margin-left: 0 !important;
    }
}
