/** Layout styles */

.main-content {
    height: 100vh;
    padding-top: $header-height;
    padding-bottom: $footer-height;
}

.page-content {
    // height: calc(100% - $grid-gutter-width;
    // height: calc(100% - 140px);
    height: calc(100% - 60px);
    overflow: auto;
    padding-left: $grid-gutter-width;
    // padding-right: $grid-gutter-width;
    padding-right: $grid-gutter-width / 2;
    margin-top: $grid-gutter-width;
    // width: calc(100vw - 280px);
    // overflow-x: hidden;
    // position: relative;

    @media only screen and (max-width: 678px) {
        & {
            padding-left: 12px;
            padding-right: 0;
        }
    }

    // input, .react-date-picker, button {
    //     margin-bottom: 1rem;
    // }

    .input-group-append {

        .input-group-text {
            width: 131px;
            justify-content: center;
            // background-color: #fff;
            border: 1px solid #ced4da;
        }
    }

    .react-date-picker__wrapper,
    .react-time-picker__wrapper {
        border: none !important;
    }

    .react-date-picker__calendar {
        z-index: 1005;

        .react-calendar__year-view .react-calendar__tile,
        .react-calendar__decade-view .react-calendar__tile,
        .react-calendar__century-view .react-calendar__tile {
            padding: 1.3em 0.5em;
        }
    }

    .loader-container {
        min-height: calc(100vh - 154px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .boolean-checkbox {
        display: inline;

    }

    .table-responsive {
        overflow-x: visible;
    }

    table.mdb-dataTable thead .sorting_asc:before,
    table.mdb-dataTable thead .sorting_desc:after {
        top: .5rem;
    }

    .mdb-dataTables_paginate {
        display: none;
    }

    .mdb-dataTable tbody tr td[colspan] {
        text-align: center;
    }

    formcontrol.disabled {
        border-color: #a4a8bd;
        background-color: #fafafa;
    }

    .form-control.autocomplete {
        input {
            border: none;
            width: 100%;
            height: 100%;

            &:focus,
            &:active,
            &:active {
                border: none !important;
            }
        }
    }

    .memo-wrapper {
        padding-bottom: 20px;
        width: 100%;
    }

    .formLockLabel {
        min-width: 160px;
        max-width: 160px;
    }

    .showLockIconsLabel {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
    }
}

.footer {
    height: 84px;
    left: 280px;
    z-index: 3;
    overflow: hidden;

    .dark {
        color: #666666;
    }

    .light {
        color: #a3a3a3;
        font-size: 0.8em;
    }

    @media (max-width: 992px) {
        & {
            left: 0;
        }
    }
}

.vertical-collpsed {
    .foldable {
        width: calc(100% - 78px);
    }
}

.page-header {
    position: sticky;
    top: 48px;
    z-index: 100;
    background-color: #f8f8fb;
}

.breadcrumbs {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #f8f8fb;
}


.btn-login {
    background-color: #034a6d;
    border-color: #034a6d;
    color: white;

    &:hover,
    &:active,
    &:focus {
        background-color: #023853;
        border-color: #023853;
        color: white;
    }
}

.text-darkblue {
    color: #034a6d;
}


.login-page {
    .login-header {
        position: relative;

        .title {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            img {
                width: 80%;
            }
        }

    }

    .login-page-small {
        font-size: 0.8em;
    }

    .login-notice {
        color: orange;

        &:hover {
            color: rgb(206, 136, 6);
        }
    }
}

.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

.btn-light-login {
    background-color: #ffffff;
    border-color: #034a6d;
    color: #034a6d;

    &:hover,
    &:active,
    &:focus {
        background-color: #f0eeee;
        border-color: #023853;
        color: #023853;
    }
}