// Generic
label.disabled {
    color: $disabled;
}

.custom-control-input[disabled]~.custom-control-label,
.custom-control-input:disabled~.custom-control-label {
    color: $disabled;
}

.form-control:disabled,
input:disabled,
.form-control.autocomplete:disabled {
    background-color: #aaaaaa;
    // color:#a4a8bd;
    color: #6f777e;
}

.form-control.btn-primary:disabled {
    background-color: #556ee6;
    border-color: #556ee6;

    svg {
        color: white;
    }
}

.validation-error {
    display: block;
    width: 100%;
    margin-bottom: .25rem;
    font-size: 80%;
    color: #f46a6a;
}

.validation-warning {
    display: block;
    width: 100%;
    margin-bottom: .25rem;
    font-size: 80%;
    color: #f5a141;
}


.single-page-form {

    .single-page-buttons {
        display: flex;
        // justify-content: space-between;

        button {
            width: 150px;
            margin-right: 20px;

            &:disabled {
                background-color: gray;
                border: darkgray;
            }
        }
    }

    .single-page-hint-line {
        font-size: 10px;
        color: black;
    }
}

// FormEditModal
#edit-form {
    .input-group-append {

        .input-group-text {
            width: 131px;
            justify-content: center;
            // background-color: #fff;
            border: 1px solid #ced4da;
        }
    }
}

/** Autosuggest styles **/
.react-autosuggest__container.react-autosuggest__container--open {
    position: relative;
}

.react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
    max-height: min(300px, 50vh);
    position: absolute;
    overflow: auto;
    background-color: #fff;
    z-index: 2;
    width: 100%;

    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            // padding: 5px;
        }
    }
}

// PageButton
.btn-label .label-icon {
    position: absolute;
    width: 32px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: hsla(0, 0%, 100%, .1);
    border-right: 1px solid hsla(0, 0%, 100%, .1);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

// PageText
.textSectionTitle {
    font-size: 1.15em;
    padding-bottom: 6px;
    color: #200020;
}

// PageList

.task-list-table {
    border-collapse: separate;
    border-spacing: 0 12px;

    thead {
        tr {
            background-color: $card-bg;

            th {
                i {
                    color: #aaaaaa;
                    padding-left: 5px;
                    padding-right: 0px;
                    cursor: pointer;
                }
            }
        }
    }
}

.new-task-list-table {
    border-collapse: separate;
    border-spacing: 0;

    .task-success {
        color: #1AAA7F;
    }

    .task-alert {
        color: #C65757;
    }

    .task-primary {
        color: $primary;
    }

    .task-info {
        color: $info;
    }

    .task-pink {
        color: $pink;
    }

    .task-icon {
        width: 25px;
    }

    tr td {
        border-top: 1px solid #CDDAE2;
    }

    tr:first-child td {
        border-top: none;
    }

    td.noborder {
        border-top: none;
        width: 20px;
        padding: 0;
    }

    .pending {
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }


    .lock-status {
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .border-line {
        border: 1px solid #CCDAE2;
    }

    .review-status {
        margin-left: 10px;
    }

    @media only screen and (max-width: 678px) {
        .mobile-hide {
            display: none;
        }
    }
}


// PageSection
.page-section {
    margin-bottom: calc(67px + 16px);
    background: #fff;
    padding: 1rem;

    @media (max-width: 992px) {
        & {
            padding: 0.5rem;
        }
    }
}

.foldable-group {
    position: absolute;
    bottom: 84px;
    width: calc(100% - 280px);
    max-height: calc(50% - 84px);
    // margin-left: -36px;
    padding: 0;
    right: 0;
    z-index: 3;
    overflow: auto;
}

.page-window .foldable-group {
    display: none;
}

.foldable {
    // background: #56a6ed;
    cursor: pointer;
    overflow: auto;

    &.stacked {
        bottom: 139px;
    }

    @media (max-width: 992px) {
        & {
            width: 100%;
        }
    }

    .foldable-title {
        padding: 12px 12px 12px 36px;
        font-size: 20px;

        .query-form-btn {
            font-size: 20px;
        }
    }

    .action-title {
        margin-right: 10px;
    }

    .foldable-content {
        padding: 0 12px 0 36px;
    }

    .collapse {
        max-height: 50%;
    }

    &.alert-warning {
        background-color: #fcf3cf;
        border-top: 1px solid #856404;

        .foldable-content {
            background-color: #fefaee;
        }
    }

    &.alert-primary {
        background-color: #b8daff;

        .foldable-content {
            background-color: #cce5ff;
        }
    }

    &.alert-danger {
        background-color: #f7cccc;

        .foldable-content {
            background-color: #fceded;
        }
    }
}

// PageTrace
.traceSection {
    font-size: 10pt;
    color: #372e10;
    margin-bottom: 10px;
    width: 100%;

    &>div {
        background-color: #d2c9ab;
        padding: 4px 10px;
        border: 1px solid #854207;
        overflow-wrap: break-word;
    }
}

// SliderInput
.custom-slider {
    margin-top: 50px;
    margin-bottom: 60px;
}

.rangeslider-horizontal .rangeslider__fill {
    background-color: #e6e6e6 !important;
}

.rangeslider__handle {
    background-color: $handle-color !important;
    border-color: $handle-color !important;
    box-shadow: none !important;

    &:after {
        background-color: $handle-color !important;
        box-shadow: none !important;
    }
}

.rangeslider__labels .rangeslider__label-item {
    white-space: nowrap;
}

/** Rc Slider styles **/
.rc-slider-mark {
    top: 30px !important;
}

.rc-slider-mark-text {
    color: #495057 !important;
}