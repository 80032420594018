.trial-watch-table {

    thead,
    tbody {
        td {
            &.col-session {
                max-width: 160px;
            }

            &.col-env {
                max-width: 60px;
            }

            &.col-project {
                max-width: 90px;
            }

            &.col-ip {
                max-width: 125px;
            }
        }
    }
}