.button-menu-icon {
    width: 30px;
    height: 24px;
    min-width: 30px !important;
    max-width: 30px !important;
    padding-left: 6px !important;
    padding-right: 10px !important;
    font-size: 20px;
}

.button-menu-title {
    font-size: 1.15em;
}

.button-right-text {
    float: right;
    text-align: right;
    padding-left: 8px;
    font-size: 0.8em;
}

.button-right-badge {
    background: radial-gradient(5px -9px, circle, white 8%, red 26px);
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    /* one half of ( (border * 2) + height + padding ) */
    color: white;
    height: 100%;
    min-width: 16px;
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 6px;
    padding-left: 6px;
}