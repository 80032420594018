@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css);

a:hover {
    cursor: pointer;
}

.noti-icon .badge {
    left: 23px;
}

.mt-2 {
    margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
    float: right;
}

.rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
}

.dz-message {
    text-align: center;
    padding: 30px;
}

// kanban board
.fcYNFc,
.sc-AxmLO.gmtmqV {
    background-color: $card-bg !important;
    margin: 0 20px 0 0 !important;
}

.fcYNFc {
    background-color: #ffffff !important;
}

.task-box {
    border: 1px solid $border-color;
}

.react-datepicker-wrapper {
    .react-datepicker__input-container {
        button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    width: 100% !important;
}

.ReactModal__Overlay {
    z-index: 1001 !important;
}

.fc-event .fc-content {
    padding: 5px;
    color: #FFF;
}

.chat-conversation .right .conversation-list {
    margin-right: 15px;
}

.external-event {
    &:hover {
        cursor: pointer;
    }
}

.hide {
    display: none;
}

.rating-container {
    background-color: transparent !important;
}

.input-group-append {
    z-index: 0;
}

.input-color {
    color: $input-color !important;
}

.sketch-picker {
    position: absolute;
    z-index: 1;
}

span {
    font-weight: 500;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-move {
    cursor: move;
}

#sidebar-menu {
    ul {
        li {
            a {
                p {
                    margin-bottom: 0;
                }

                i,
                svg {
                    font-size: 1rem;
                    min-width: 1.5rem;
                }
            }

            .badge {
                color: $sidebar-menu-sub-item-color;
                background-color: #feffff47;
            }

            ul.sub-menu {
                a {
                    justify-content: space-between;
                }

                &.mm-collapse {
                    display: block;
                    transition: all ease-out 0.2s;
                    overflow: hidden;

                    &:not(.mm-show) {
                        max-height: 0;
                    }
                }
            }
        }
    }
}

.tooltip {
    font-size: 0.6rem;
    pointer-events: none;
    transition-delay: .35s;
}

.breadcrumb-link {
    color: #495057;

    &:hover {
        color: #495057DD;
    }
}


/************** Horizontal timeline **************/


.hori-timeline {
    .events {
        .event-list {
            text-align: center;
            display: block;

            .event-down-icon {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    top: 16px;
                    left: 0;
                    right: 0;
                    border-bottom: 3px dashed $gray-300;
                }

                .down-arrow-icon {
                    position: relative;
                    background-color: $card-bg;
                    padding: 4px;
                }
            }

            &:hover {
                .down-arrow-icon {
                    animation: fade-down 1.5s infinite linear;
                }
            }

            &.active {
                .down-arrow-icon {
                    animation: fade-down 1.5s infinite linear;

                    &:before {
                        content: "\ec4c";
                    }
                }
            }
        }
    }
}


/************** vertical timeline **************/

.verti-timeline {
    border-left: 3px dashed $gray-300;
    margin: 0 10px;

    .event-list {
        position: relative;
        padding: 0px 0px 40px 30px;

        .event-timeline-dot {
            position: absolute;
            left: -9px;
            top: 0px;
            z-index: 9;
            font-size: 16px;
        }

        .event-content {
            position: relative;
            border: 2px solid $border-color;
            border-radius: 7px;
        }

        &.active {
            .event-timeline-dot {
                color: $primary;
            }
        }

        &:last-child {
            padding-bottom: 0px;
        }
    }
}

.save-btn {
    position: relative;

    .spinner.spinner-border {
        position: absolute;
        height: 1.5rem;
        width: 1.5rem;
        left: calc(50% - 0.75rem);
    }
}

.form-control.autocomplete {
    padding: .375rem 1.75rem .375rem .75rem;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23495057' d='M2 4L0 2h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active {
    background-color: transparent;
    color: #495057 !important;
}

.table-sm {
    .btn-sm {
        line-height: 1;
    }
}

.custom-switch {
    .custom-control-label {

        &::before,
        &::after {
            cursor: pointer;
        }
    }
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.sigCanvas {
    border: 1px solid black;
}

.dropdown-item {
    cursor: pointer;
    line-height: initial;
}


.lock-icon {
    width: 50px;
}

.pdfViewer {
    width: 100%;
    min-height: 500px;
}

.sortable-table {

    .fa-sort,
    .fa-sort-asc,
    .fa-sort-desc {
        color: #aaaaaa;
        padding-left: 5px;
        padding-right: 0px;
        cursor: pointer;
    }
}

.bd-highlight {
    background-color: rgba(86, 61, 124, .15);
    border: 1px solid rgba(86, 61, 124, .15);
}

.ag-header-align-right .ag-header-cell-label {
    justify-content: end;
}

.ag-header-align-left .ag-header-cell-label {
    justify-content: start;
}

.custom-date-picker {

    .react-datepicker__header {
        background: #556ee6;
    }

    .input-group-text {
        padding: 0;
        border: none;

        button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.input-group-text {
    padding: 0;
    border: none;
}

.disabled .input-group-text {
    background-color: #727272;
    color: #ffffff;
}

.react-datepicker-popper {
    z-index: 1001;
}

.btn {
    &.btn-xs {
        padding: 0.2rem 0.5rem;
    }
}

.react-datepicker__header {
    background: #556ee6;

    select {
        flex: 1;
        text-align: center;
        background: transparent;
        color: white;
    }

    option {
        color: black;
    }

    .react-datepicker__day-name {
        color: white;
    }
}




.sort-icon {
    color: #aaaaaa;
    margin-left: 5px;
    cursor: pointer;
    width: 14px;
}

.table thead th {
    white-space: nowrap;
}

// .table th, .table td {
//     border-color: #ced4da;
// }

// Remove default indentation for global
.row {
    margin-left: 0;
    margin-right: 0;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
    padding-left: 0;
    padding-right: 0;
}

/** Input field styles */

.unit-input-field {
    max-width: 250px;

    .input-group-append {
        width: 100px;
    }
}

.form-field-group {
    display: flex;
    flex-wrap: wrap;

    >div {
        padding-right: 20px;
    }

    @media only screen and (max-width: 678px) {
        & {
            >div {
                width: 100%;
            }
        }
    }
}


.medication-suggest {
    min-width: 350px;
}

.medication-dose-loading {
    position: absolute;
    left: 250px;
    top: 5px;
}

.progress {
    margin-top: 20px;
    margin-bottom: 20px;
    // margin-bottom: 1.25rem;
}

.date-input-field {
    width: 200px;
}

.drop-down {
    min-width: 240px;
}

// .col-form-label {
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }

.bg-default {
    background-color: #CDDAE2;
}

// @each $color,
// $value in $theme-colors {
//     .soft-#{$color} {
//         color: rgba(($value), 0.25) !important;
//     }

//     .text-#{$color} {
//         color: $value !important;
//     }
// }

.app-search {
    position: relative;
}

@keyframes growDown {
    0% {
        transform: scaleY(0)
    }

    80% {
        transform: scaleY(1.1)
    }

    100% {
        transform: scaleY(1)
    }
}

#page-topbar {
    z-index: 1002;
}



.tab {
    cursor: pointer;
    margin-right: 20px;
    padding: 10px;
    position: relative;

    &.active {
        color: #181C28;
        font-weight: bold;

        .active-line {
            background-color: #069CE6;
            height: 5px;
            border-radius: 4px;
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
        }
    }

    .text {
        margin: 0.5rem;
    }

    .badge {
        margin-left: 5px;
    }

    .badge-success {
        background-color: #069CE6;
    }

    .badge-tab-active {
        background-color: #E8F5FB;
        color: #069CE6;
    }

    @media only screen and (max-width: 786px) {
        & {
            padding: 5px;
            margin-right: 0;

            .text {
                margin: 0;
                font-size: 13px;
            }

            .badge {
                font-size: 13px;
            }
        }
    }


    @media only screen and (max-width: 472px) {
        & {

            .text {
                margin: 0;
                font-size: 10px;
            }

            .badge {
                font-size: 10px;
            }
        }
    }
}

.back-hub-div {
    img {
        width: 20px;
        margin-right: 0.25rem;
    }
}


.resend-code.active {
    color: #023853;
    font-weight: 500;
}