.dash-stat {
    &.btn-success {
        background-color: #63c7bc;
        border-color: #63c7bc;
    }

    &.btn-danger {
        background-color: #ff9393;
        border-color: #ff9393;
    }

    &.btn-default {
        background-color: #7E98A7;
        border-color: #7E98A7;
    }

    .button {
        background-color: white;
        margin-bottom: 0;
        padding: 10px;

        &.primary {
            color: #007BFF;
        }

        &.success {
            color: #63c7bc;
        }

        &.danger {
            color: #ff9393;
        }

        &.secondary {
            color: #6C757D;
        }

        &.default {
            color: #7E98A7;
        }
    }
}