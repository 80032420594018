
// 
// breadcrumb.scss
//


// Breadcrumb item arrow
.breadcrumb-item {
    > a {
        color: $breadcrumb-link-color;

        &:hover {
            text-decoration: underline !important;
        }
    }
}
  

.breadcrumb-item + .breadcrumb-item::before {
    font-size: 24px;
    line-height: 24px;
}